import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button } from '@mui/material';
//
import Iconify from '../Iconify';
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import ResumeUploadSuccess  from './ResumeUploadSuccess';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleFileCustom.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFileCustom({ error = false, file, onUpload, onRemove, helperText, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '12% 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        {/* <BlockContent /> */}
        {file ?  (
          <>
        <ResumeUploadSuccess file={file}/>
        </>
        
        )  : <BlockContent /> }
        {/* {file && (
          <Image
            alt="file preview"
            // src={<Iconify icon="eva:close-fill" />}
            // src={typeof file === 'string' ? file : file.preview}
            src='blob:http://localhost:3030/ec69db63-4fa5-4c1e-bea0-5aa452c94008'
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
            }}
          />
        )} */}
      </DropZoneStyle>
      {file && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{pt:3}}>
        <Button color="inherit" size="small" onClick={onRemove}>
          Clear
        </Button>
        <Button size="small" variant="contained" onClick={onUpload}>
          Update Resume
        </Button>
      </Stack>
      )}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
