import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button } from '@mui/material';
//
import Iconify from '../Iconify';
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import ResumeUploadSuccess from './ResumeUploadSuccess';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleResume.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  helperText: PropTypes.node,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  //   setSelectedFile:PropTypes.func
};

export default function UploadSingleResume({
  error = false,
  file,
  onUpload,
  onRemove,
  disabled,
  helperText,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
    disabled,
  });

  const [selectedFile, setSelectedFile] = useState(file || null);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleRemove = () => {
    onRemove()
    setSelectedFile(null)
  };

  useEffect(() => {
    setSelectedFile(file || null);
  }, [file]);

  useEffect(() => {
    if (isMounted && onRemove && !file) {
      handleRemove();
    }
  }, [onRemove, file, isMounted]);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '12% 0',
          }),
          ...(disabled && { pointerEvents: 'none', opacity: 0.5 }),
        }}
      >
        <input {...getInputProps()} disabled={disabled} />
        {selectedFile ? (
          <>
            <ResumeUploadSuccess file={file} />
          </>
        ) : (
          <BlockContent />
        )}
      </DropZoneStyle>
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
