import { useState } from 'react';
// @mui
import { MenuItem, Stack, Tooltip, Link } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function LanguagePopover() {


  return (
    <>
    <Tooltip title="Help">
        <Link href="https://varsitypro.zendesk.com/" target="_blank">
      <IconButtonAnimate
        sx={{
          width: 40,
          height: 40,
        }}
      >
        {/* Help button */}
        <Iconify icon="eva:question-mark-circle-fill" width={24} height={24} />
      </IconButtonAnimate>
        </Link>

      </Tooltip>

    </>
  );
}
