import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { m } from 'framer-motion';
import useAuth  from '../../../hooks/useAuth';
import Iconify from '../../Iconify';

const UnverifiedUniversityDialog = () => {
  const { user } = useAuth();

  const isUnverifiedUniversity = user?.role === 'university' && !user?.isUniversityAccountVerified;

  return (
    <Dialog 
      open={isUnverifiedUniversity} 
      onClose={() => {}}
      PaperProps={{
        style: {
          borderRadius: 16,
          background: 'linear-gradient(135deg, #E8F0FE 0%, #E0E7FF 100%)',
          border: '2px solid #A5B4FC',
          maxWidth: 500,
          width: '100%',
        },
      }}
    >
      <DialogContent>
        <m.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 2, color: '#4338CA', fontWeight: 700, fontSize: '1.5rem' }}>
            <Iconify icon="mdi:alert-circle" width={32} height={32} style={{ color: '#FBBF24' }} />
            Account Verification
          </DialogTitle>

          <m.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            style={{ background: 'white', borderRadius: 12, padding: 24, marginTop: 24, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          >
            <h3 style={{ display: 'flex', alignItems: 'center', gap: 8, fontSize: '1.25rem', fontWeight: 600, color: '#4338CA', marginBottom: 12 }}>
              <Iconify icon="mdi:office-building" width={24} height={24} />
              Verification in Progress
            </h3>
            <p style={{ color: '#4B5563', marginBottom: 12 }}>
              Your university account is currently under review. We're working diligently to verify your credentials.
            </p>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: '#D97706', fontWeight: 500 }}>
              <Iconify icon="mdi:clock-outline" width={20} height={20} />
              <span>Estimated time: 30 minutes or less</span>
            </div>
          </m.div>

          <m.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            style={{ background: '#4338CA', color: 'white', borderRadius: 12, padding: 24, marginTop: 24, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          >
            <h3 style={{ fontSize: '1.25rem', fontWeight: 600, marginBottom: 12 }}>Need Assistance?</h3>
            <p style={{ marginBottom: 16 }}>
              If you have any urgent concerns, please don't hesitate to contact your dedicated Relationship Manager.
            </p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 12, background: 'white', color: '#4338CA', padding: '12px 24px', borderRadius: 9999, fontWeight: 700, fontSize: '1.125rem' }}>
              <m.div animate={{ scale: [1, 1.2, 1] }} transition={{ duration: 1, repeat: Infinity }}>
                <Iconify icon="mdi:phone" width={24} height={24} />
              </m.div>
              <span>+91 7428109069</span>
            </div>
          </m.div>
        </m.div>

        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          style={{ marginTop: 24, textAlign: 'center', fontSize: '0.875rem', color: '#6B7280' }}
        >
          Thank you for your patience. We're excited to have you on board!
        </m.div>
      </DialogContent>
    </Dialog>
  );
};

export default UnverifiedUniversityDialog;