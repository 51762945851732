import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  Box, Button, Dialog, DialogContent, DialogTitle, Typography, IconButton, 
  Stepper, Step, StepLabel, StepContent, TextField, Tooltip, LinearProgress,
  Fab, Zoom, Stack, MenuItem
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { 
  Close, School, Group, People, PlayCircleOutline, ChevronRight, ChevronLeft, Rocket, ArrowForward
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { PATH_UNIVERSITY } from '../../../../../routes/paths';
import { setOnboardingComplete, getOnboardingProgress, openModal, closeModal } from '../../../../../redux/slices/uniOnboarding/uniOnboarding';
import axios from '../../../../../utils/axios';
import Iconify from '../../../../Iconify';
import LogoLong from '../../../../Logo';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(32, 201, 151, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(32, 201, 151, 0);
  }
`;

const FloatingButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
  animation: `${pulse} 2s infinite`,
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontWeight: 'bold',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '90%',
    maxWidth: '1200px',
    borderRadius: theme.spacing(2), 
    overflow: 'hidden',
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));



const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '80vh',
}));

const MainContent = styled(Box)(({ theme }) => ({
  width: '70%',
  padding: theme.spacing(4),
  overflowY: 'auto',
}));

const Navigation = styled(Box)(({ theme }) => ({
  width: '30%',
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(4),
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

const StepButton = styled(Button)(({ theme, active }) => ({
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: active ? theme.palette.primary.light : 'transparent',
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.light : theme.palette.action.hover,
  },
}));

const HelpButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const steps = [
  { id: 'courses', label: 'Add Courses', icon: <School /> },
  { id: 'students', label: 'Add Students', icon: <Group /> },
  { id: 'teammates', label: 'Add Teammates', icon: <People /> },
  { id: 'tutorials', label: 'View Tutorials', icon: <PlayCircleOutline /> },
];

const LargeButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(2, 4),
    fontSize: '1.2rem',
    marginTop: theme.spacing(4),
  }));

  const ButtonTextCourse = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    textAlign: 'left',
    fontWeight: 'bold',
  }));

const UniversityWelcomeTourBanner = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const progress = useSelector((state) => state.uniOnboarding.progress);
  const open = useSelector((state) => state.uniOnboarding.modalOpen);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: 'admin',
    },
  });

  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'moderator', label: 'Moderator', disabled: true },
    { value: 'contributor', label: 'Contributor', disabled: true },
  ];

  console.log(progress);


//   useEffect(() => {
//     dispatch(updateOnboardingProgress({ courseAdded: false }));
//   }, [dispatch]);

  useEffect(() => {
    if (open) {
      if (progress.courses) {
        setActiveStep(1);
      } if (progress.students) {
        setActiveStep(2);
      }  if (progress.teammates) {
        setActiveStep(3);
      }
    }
  }, [open]);

  const handleClose = () => dispatch(closeModal());
  const handleOpen = () => dispatch(openModal());

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // dispatch(updateOnboardingProgress({ [steps[activeStep].id]: true }));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    dispatch(setOnboardingComplete());
    navigate(PATH_UNIVERSITY.general.app);
    window.location.reload();
    handleClose();
  };

  const handleNavigateToCourseManager = () => {
    navigate(PATH_UNIVERSITY.general.courseManager);
    handleClose();
  };

  const handleAddStudent = () => {
    navigate(PATH_UNIVERSITY.general.addStudent);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      dispatch(getOnboardingProgress());
    }

  }, [dispatch, open]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post('/api/v1/university_management/university_role_update/', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status >= 200 && response.status < 300) {
        enqueueSnackbar(`${data.role} user - ${data.firstName} ${data.lastName} has been invited`);
        reset();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'An error occurred', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };




  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>Add Your Courses</Typography>
            <Typography paragraph>
              Start by adding your courses and academic structure, this will help you manage your students and their progress effectively.
            </Typography>
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
              
            <LargeButton
              variant="outlined"
              color="primary"
              startIcon={<School />}
              endIcon={<ArrowForward />}
              fullWidth
              onClick={handleNavigateToCourseManager}
            >
              <ButtonTextCourse variant="button">
                Go to Course Manager
              </ButtonTextCourse>
            </LargeButton>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>Add Students</Typography>
            <Typography paragraph>
              Now, let's add students to your courses. You can add them individually or import them in bulk.
            </Typography>
            <Box sx={{ display: 'flex'}}>
            <LargeButton
              variant="outlined"
              color="primary"
              startIcon={<School />}
              endIcon={<ArrowForward />}
              fullWidth
              onClick={handleAddStudent}
            >
              <ButtonTextCourse variant="button">
                Let's Add Students
              </ButtonTextCourse>
            </LargeButton>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Typography variant="h5" gutterBottom>Invite Team Members</Typography>
            <Typography paragraph>
              Collaborate with your colleagues by inviting them to join the platform. They'll receive an email with instructions to set up their account.
            </Typography>
            <Stack spacing={3}>
              <Stack direction="row" spacing={2}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: 'First name is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Teammate's First Name"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: 'Last name is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Teammate's Last Name"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Stack>
              <Controller
                name="email"
                control={control}
                rules={{ 
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Email"
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Role"
                    fullWidth
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={role.value}
                        value={role.value}
                        disabled={role.disabled}
                      >
                        <Tooltip title={role.disabled ? "Upgrade your plan to add this role" : ""}>
                          <span>{role.label}</span>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                startIcon={<Iconify icon="eva:person-add-fill" />}
              >
                Send Invitation
              </LoadingButton>
            </Stack>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography variant="h5" gutterBottom>Explore Our Resources</Typography>
            <Typography paragraph>
              We've prepared comprehensive resources to help you get the most out of our platform. Check out our documentation and video tutorials.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
            <a href="https://youtu.be/ldBoyLzBvp0?feature=shared" target="_blank" rel="noopener noreferrer">
              <Button variant="outlined" color="primary" startIcon={<PlayCircleOutline />}>
                Watch Video Tutorials
              </Button>
            </a>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <Header>
          <LogoLong sx={{mt:2, mr :2}}/>
          <Typography variant="h5">Get started with VarsityPro</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </Header>
        <DialogContent sx={{ p: 0 }}>
          <Content>
            <MainContent>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {renderStepContent(index)}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            onClick={index === steps.length - 1 ? handleFinish : handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </MainContent>
            <Navigation>
              <Typography variant="h6" gutterBottom>Setup Progress</Typography>
              <LinearProgress 
                variant="determinate" 
                value={Object.values(progress).filter(Boolean).length / steps.length * 100} 
                sx={{ mb: 3 }}
              />
              {steps.map((step, index) => (
                <Tooltip title={progress[step.id] ? "Completed" : "Pending"} placement="left" key={step.id}>
                  <StepButton
                    fullWidth
                    startIcon={step.icon}
                    onClick={() => setActiveStep(index)}
                    active={activeStep === index}
                  >
                    {step.label}
                  </StepButton>
                </Tooltip>
              ))}
            </Navigation>
          </Content>
        </DialogContent>
      </StyledDialog>
      {!open && (
        <Zoom in={!open} timeout={300}>
          <FloatingButton
            variant="extended"
            onClick={handleOpen}
            aria-label="continue onboarding"
          >
            <Rocket />
            <ButtonText variant="button">
              Continue Onboarding
            </ButtonText>
          </FloatingButton>
        </Zoom>
      )}
    </>
  );
};

export default UniversityWelcomeTourBanner;