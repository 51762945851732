import { createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { dispatch } from '../../store';

const initialState = {
  isLoading: false,
  error: null,
  profileGeneral: {},
  profileSummary: null,
  resumeFileUpload: null,
  studentResume: null,

  // mbti test
  mbtiTestAnswers: [],
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfileGeneral(state, action) {
      state.isLoading = false;
      state.profileGeneral = action.payload;
    },

    getProfileSummary(state, action) {
      state.isLoading = false;
      state.profileSummary = action.payload;
    },

    uploadResumeSuccess(state, action) {
      state.isLoading = false;
      state.resumeFileUpload = action.payload;
    },

    getStudentResumeSuccess(state, action) {
      state.isLoading = false;
      state.studentResume = action.payload;
    },

    // MBTI TEST
    setMbtiTestAnswers(state, action) {
      state.mbtiTestAnswers = action.payload;
    },
  },
});

export default slice.reducer;

export function getProfileGeneralReq() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/ab/student/general/`);
      dispatch(slice.actions.getProfileGeneral(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfileSummaryReq() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/ab/student/summary/`);
      dispatch(slice.actions.getProfileSummary(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------------------------------------------------

export function uploadResume(file) {
  return async () => {
    dispatch(slice.actions.startLoading());
    console.log(file)
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post('/api/v1/ab/student/resume/latest/', formData);
      dispatch(slice.actions.uploadResumeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//----------------------------------------------------------------------

export function getStudentResume() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/ab/student/resume/latest/`);
      dispatch(slice.actions.getStudentResumeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setMbtiTestAnswersReq(answers) {
  return () => {
    const validAnswers = Array.isArray(answers) ? answers : [];
    dispatch(slice.actions.setMbtiTestAnswers(validAnswers));
  };
}
