import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  RHCompanies : [],
};

const slice = createSlice({
  name: 'rh',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JOBS
    getRHCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.RHCompanies = action.payload;
    },

    // // Get SINGLE JOB
    // getJobSuccess(state, action) {
    //   state.isLoading = false;
    //   state.job = action.payload;
    // },

    // // Get SINGLE JOB
    // getCustomQuestions(state, action) {
    //   state.isLoading = false;
    //   state.customQuestions = action.payload;
    // },

    // // CREATE JOBS
    // createJobSuccess(state, action) {
    //   const newJob = action.payload;
    //   state.isLoading = false;
    //   state.jobCreate = newJob;
    // },

    // // GET JOBS
    // getCompanyJobsSuccess(state, action) {
    //   state.isLoading = false;
    //   state.companyJobsList = action.payload;
    // },


    // // GET Student Applied JOBS
    // getStudentAppliedJobsSuccess(state, action) {
    //   state.isLoading = false;
    //   state.studentAppliedJobsList = action.payload.results;
    //   state.AppliedJobsPageInfo = {totalJobs: action.payload.count}
    // },

    // UPDATE EVENT
    // updateEventSuccess(state, action) {
    //   const event = action.payload;
    //   const updateEvent = state.events.map((_event) => {
    //     if (_event.id === event.id) {
    //       return event;
    //     }
    //     return _event;
    //   });

    //   state.isLoading = false;
    //   state.events = updateEvent;
    // },

    // DELETE EVENT
    // deleteEventSuccess(state, action) {
    //   const { eventId } = action.payload;
    //   const deleteEvent = state.events.filter((event) => event.id !== eventId);
    //   state.events = deleteEvent;
    // },

    // SELECT EVENT
    // selectEvent(state, action) {
    //   const eventId = action.payload;
    //   state.isOpenModal = true;
    //   state.selectedEventId = eventId;
    // },

    // SELECT RANGE
    // selectRange(state, action) {
    //   const { start, end } = action.payload;
    //   state.isOpenModal = true;
    //   state.selectedRange = { start, end };
    // },

    // OPEN MODAL
    // openModal(state) {
    //   state.isOpenModal = true;
    // },

    // CLOSE MODAL
    // closeModal(state) {
    //   state.isOpenModal = false;
    //   state.selectedEventId = null;
    //   state.selectedRange = null;
    // },
  },
});

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function listCompanies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/recruiterhub/company/view/`);
      dispatch(slice.actions.getRHCompaniesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


// export function getJob(id) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/api/v1/jobs/${id}`);
//       dispatch(slice.actions.getJobSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------


// export function getCustomQuestion(jobId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/api/v1/jobs/custom-questions/${jobId}/`);
//       dispatch(slice.actions.getCustomQuestions(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // -------------------------------------------------------------------
// export function createJob(newJob) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/v1/jobs/new/', newJob);
//       dispatch(slice.actions.createJobSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function listCompanyJobs( isActive) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/api/v1/jobs/company/view/?active=${isActive}`);
//       dispatch(slice.actions.getCompanyJobsSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }



// // ----------------------------------------------------------------------

// export function listStudentAppliedJobs( pageNumber ) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/api/v1/jobs/applied-jobs/?page=${pageNumber}`);
//       dispatch(slice.actions.getStudentAppliedJobsSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }


// export function updateEvent(eventId, updateEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/update', {
//         eventId,
//         updateEvent,
//       });
//       dispatch(slice.actions.updateEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function deleteEvent(eventId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post('/api/calendar/events/delete', { eventId });
//       dispatch(slice.actions.deleteEventSuccess({ eventId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function selectRange(start, end) {
//   return async () => {
//     dispatch(
//       slice.actions.selectRange({
//         start: start.getTime(),
//         end: end.getTime(),
//       })
//     );
//   };
// }
