// @mui
import { Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// assets
import { UploadIllustration } from '../../assets';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
    width: 50,
    height: 50,
    paddingLeft:1,
    marginTop: 1,
    marginLeft: 10,
    
    flexShrink: 0,
  }));




export default function ResumeUploadSuccess({file}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {/* <UploadIllustration sx={{ width: 220 }} /> */}

      {(() => {
          if (file?.type === 'application/pdf') {
            return <IconStyle icon={'bi:filetype-pdf'} color="#DD2025" />;
          } if (file?.type === 'application/msword') {
            return <IconStyle icon={'ant-design:file-word-twotone'} color="#1551B4" />;
          } 
            return <IconStyle icon={'mdi:file-document'} />;
          
        })()}

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          Resume has been uploaded.
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {file?.path}
        </Typography>
      </Box>
    </Stack>
  );
}
