// routes
import { useEffect } from 'react';
import { PATH_DASHBOARD, PATH_STUDENT, PATH_UNIVERSITY, PATH_COMPANY } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIconfy = (name) => <Iconify icon={name} width={22} height={22} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),

  // ---------------------------------------
  // Student Icons
  jobs: getIconfy('eva:briefcase-fill'),
  events: getIconfy('carbon:events'),
  opportunities: getIconfy('maki:college'),
  placements: getIconfy('fluent:people-audience-24-filled'),
  internships: getIconfy('fluent:people-team-24-filled'),
  resume: getIconfy('mingcute:document-line'),
  StudentSettings: getIconfy('eva:settings-fill'),
  studentCareerTrack: getIconfy('eva:arrowhead-up-outline'),
  ctroadmap: getIconfy('streamline:arrow-roadmap'),
  test: getIconfy('healthicons:i-exam-multiple-choice'),
  // Student CH
  careerHubHome: getIconfy('eva:home-outline'),
  personalityTest: getIconfy('icon-park-outline:thinking-problem'),
  mockInterview: getIconfy('material-symbols:frame-person-mic'),

  // ---------------------------------------
  // Univeristy Icons
  addStudent: getIconfy('eva:person-add-fill'),
  groups: getIconfy('eva:people-fill'),
  studentActivity: getIconfy('eva:activity-fill'),
  uniJobs: getIconfy('eva:briefcase-fill'),
  studentDetails: getIconfy('eva:person-fill'),
  univeristyEvent: getIconfy('material-symbols:event'),
  uniWorkshops: getIconfy('material-symbols:computer-outline'),
  lists: getIconfy('ic:outline-list'),
  scheduleTest: getIconfy('mingcute:schedule-line'),
  academicStructure : getIconfy('mingcute:user-setting-fill'),
  testsHome : getIconfy('eva:home-outline'),

  uniAnalytics: {
    main : getIconfy('eva:bar-chart-2-fill'),
    home: getIconfy('eva:home-outline'),
    students : getIconfy('eva:people-fill'),
    interviews: getIconfy('material-symbols:frame-person-mic'),
    test: getIconfy('material-symbols:thinking-problem'),
  },

  // ---------------------------------------
  // Company Icons
  newJobs: getIconfy('eva:archive-fill'),
  newJobPosting: getIconfy('ion:create-outline'),
  Applicants: getIconfy('et:profile-male'),
  testBuilder: getIconfy('material-symbols-light:build'),
  question: getIconfy('bi:question'),

};

const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRON || '';

const studentNavConfig = [
  {
    subheader: 'Home',
    items: [
      {
        roles: ['student'],
        title: 'dashboard',
        path: PATH_STUDENT.general.app,
        icon: ICONS.dashboard,
        // slugCode: 'student-dashboard',
      },
      {
        roles: ['student'],
        title: 'profile',
        path: PATH_STUDENT.user.profile,
        icon: ICONS.user,
        // slugCode: 'student-dashboard',
      },
      {
        roles: ['student'],
        title: 'Resume',
        path: PATH_STUDENT.user.resume,
        icon: ICONS.resume,
        // slugCode: 'student-dashboard',
      },
      {
        roles: ['student'],
        title: 'Test',
        path: PATH_STUDENT.user.test,
        icon: ICONS.test,
        // slugCode: 'student-dashboard',
      },
    ],
  },
  {
    subheader: 'Career',
    items: [
      {
        roles: ['student'],
        title: 'CareerHub',
        path: PATH_STUDENT.general.careerTrack,
        icon: ICONS.studentCareerTrack,
        slugCode: 'student-career-track',
        children: [
          {
            roles: ['student'],
            title: 'Home',
            path: PATH_STUDENT.general.careerTrack,
            icon: ICONS.careerHubHome,
            slugCode: 'student-career-track',
          },
          {
            roles: ['student'],
            title: 'Personality Test',
            path: PATH_STUDENT.general.mbtiTest,
            icon: ICONS.personalityTest,
          },
          {
            roles: ['student'],
            title: 'Mock Interview',
            path: PATH_STUDENT.general.mockInterview,
            icon: ICONS.mockInterview,
          },
          {
            title: 'Roadmaps',
            path: PATH_STUDENT.general.ctroadmap,
            icon: ICONS.ctroadmap,
            // slugCode: 'student-list-workshop',
          },
        ],
      },

      {
        roles: ['student'],
        title: 'Workshops',
        path: PATH_STUDENT.general.workshops,
        icon: ICONS.uniWorkshops,
        slugCode: 'student-list-workshop',
      },
      {
        roles: ['student'],
        title: 'events',
        path: PATH_STUDENT.general.events,
        icon: ICONS.events,
        slugCode: 'student-events',
      },
      {
        roles: ['student'],
        title: 'Feed',
        path: PATH_STUDENT.general.feed,
        icon: ICONS.chat,
        slugCode: 'student-list-feed',
      },
    ],
  },
  {
    subheader: 'Opportunities',
    items: [
      {
        roles: ['student'],
        title: 'Jobs',
        path: PATH_STUDENT.general.jobs,
        icon: ICONS.jobs,
        slugCode: 'student-jobs',
      },
      {
        roles: ['student'],
        title: 'Internships',
        path: PATH_STUDENT.general.internships,
        icon: ICONS.internships,
        slugCode: 'student-internships',
      },
      {
        roles: ['student'],
        title: 'Placements',
        path: PATH_STUDENT.general.hiringDrive,
        icon: ICONS.placements,
        slugCode: 'student-hiring-drive',
      },
      {
        roles: ['student'],
        title: 'opportunities',
        path: PATH_STUDENT.general.opportunities,
        icon: ICONS.opportunities,
        slugCode: 'student-opportunities',
      },
    ],
  },

  /// User Settings
  {
    subheader: 'For You',
    items: [
      {
        title: 'My Applications',
        path: PATH_STUDENT.general.jobsApplied,
        icon: ICONS.jobs,
        slugCode: 'student-jobs-applied',
        caption: 'Jobs & Internships',
      },
      {
        roles: ['student'],
        title: 'Settings',
        path: PATH_STUDENT.user.account,
        icon: ICONS.StudentSettings,
      },
    ],
  },
];

// University nav
const universityNavConfig = [
  {
    subheader: 'Home',
    items: [
      {
        roles: ['university'],
        title: 'dashboard',
        path: PATH_UNIVERSITY.general.app,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      // {
      //   roles: ['university'],
      //   title: 'Recruiter Hub',
      //   path: PATH_UNIVERSITY.general.rhCompanyList,
      //   icon: ICONS.newJobPosting,
      //   slugCode: 'university-rh-company-list',
      //   info: <Label color="warning" startIcon={<Iconify icon="mdi:crown-outline" />} />,
      //   children: [
      //     {
      //       roles: ['university'],
      //       title: 'Companies',
      //       path: PATH_UNIVERSITY.general.rhCompanyList,
      //       slugCode: 'university-rh-company-list',
      //     },
      //     {
      //       roles: ['university'],
      //       title: 'Messages',
      //       path: PATH_UNIVERSITY.general.rhMessages,
      //       slugCode: 'university-rh-messages',
      //     },
      //   ],
      // },
      {
        roles: ['university'],
        title: 'Tests',
        path: PATH_UNIVERSITY.general.testsList,
        icon: ICONS.test,
        children: [
          {
            roles: ['university'],
            title: 'Tests Home',
            path: PATH_UNIVERSITY.test.home,
            icon: ICONS.testsHome,
          },
          {
            roles: ['university'],
            title: 'All Tests',
            path: PATH_UNIVERSITY.general.testsList,
            icon: ICONS.lists,
          },
        ],
      },
      {
        roles: ['university'],
        title: 'Analytics',
        icon: ICONS.uniAnalytics.main,
        path: PATH_UNIVERSITY.general.analyticsHome,
        slugCode: 'university-analytics',
        children: [
          {
            roles: ['university'],
            title: 'Home',
            icon: ICONS.uniAnalytics.home,
            path: PATH_UNIVERSITY.general.analyticsHome,
            slugCode: 'university-analytics',
          }
        ]

      },
      {
        roles: ['university'],
        title: 'feed',
        path: PATH_UNIVERSITY.general.feed,
        icon: ICONS.chat,
        slugCode: 'university-list-feed',
      },
      {
        roles: ['university'],
        title: 'Hiring Drive',
        path: PATH_UNIVERSITY.general.hiringDrive,
        icon: ICONS.placements,
        slugCode: 'university-hiring-drive',
      },
      {
        roles: ['university'],
        title: 'jobs',
        path: PATH_UNIVERSITY.general.jobs,
        icon: ICONS.uniJobs,
        slugCode: 'university-jobs',
        children: [
          {
            roles: ['university'],
            title: 'All Jobs',
            path: PATH_UNIVERSITY.general.jobs,
            slugCode: 'university-jobs',
          },
          {
            roles: ['university'],
            title: 'Approvals',
            path: PATH_UNIVERSITY.general.jobsApproval,
            slugCode: 'university-jobs-approval',
          },
        ],
      },
      {
        roles: ['university'],
        title: 'Workshops',
        path: PATH_UNIVERSITY.general.workshops,
        icon: ICONS.uniWorkshops,
        slugCode: 'university-list-workshop',
      },
      {
        roles: ['university'],
        title: 'Events',
        path: PATH_UNIVERSITY.general.events,
        icon: ICONS.univeristyEvent,
        slugCode: 'university-events',
      },
      {
        roles: ['university'],
        title: 'Student Management',
        path: PATH_UNIVERSITY.general.studentDetails,
        icon: ICONS.groups,
        slugCode: 'university-student-activity',
        children: [
          {
            roles: ['university'],
            title: 'student details',
            path: PATH_UNIVERSITY.general.studentDetails,
            icon: ICONS.studentDetails,
            slugCode: 'university-student-details',
          },
          {
            roles: ['university'],
            title: 'student activity',
            path: PATH_UNIVERSITY.general.studentActivity,
            icon: ICONS.studentActivity,
            slugCode: 'university-student-activity',
          },
          {
            roles: ['university'],
            title: 'groups',
            path: PATH_UNIVERSITY.general.groups,
            icon: ICONS.groups,
            slugCode: 'university-groups',
          },
          {
            roles: ['university'],
            title: 'add student',
            path: PATH_UNIVERSITY.general.addStudent,
            icon: ICONS.addStudent,
            slugCode: 'university-add-student',
          },
          {
          roles: ['university'],
          title: 'Academic Structure',
          path: PATH_UNIVERSITY.general.academicStructureManager,
          icon: ICONS.academicStructure,
          },
        ],
      }
      
    ],
  },
  {
    subheader: 'For You',
    items: [
      {
        roles: ['university'],
        title: 'Settings',
        path: PATH_UNIVERSITY.user.account,
        icon: ICONS.StudentSettings,
      },
    ],
  },
];

// Company nav
const companyNavConfig = [
  {
    subheader: 'Home',
    items: [
      {
        roles: ['company'],
        title: 'dashboard',
        // path: PATH_COMPANY.general.newJobPosting,
        path: PATH_COMPANY.general.app,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        roles: ['company'],
        title: 'New Job Posting',
        path: PATH_COMPANY.general.newJobPosting,
        icon: ICONS.newJobPosting,
      },
      {
        roles: ['company'],
        title: 'Jobs',
        path: PATH_COMPANY.general.JobsList,
        icon: ICONS.newJobPosting,
      },
      {
        roles: ['company'],
        title: 'Applicants',
        path: PATH_COMPANY.general.JobApplicants,
        icon: ICONS.Applicants,
      },
    ],
  },
  {
    subheader: 'Admin',
    items: [
      {
        roles: ['company'],
        title: 'Add new job',
        path: PATH_COMPANY.admin.JobCreate,
        slugCode: 'admin-job-pages',
      },
      {
        roles: ['company'],
        title: 'Jobs List',
        path: PATH_COMPANY.admin.JobList,
        slugCode: 'admin-job-pages',
      },
      {
        roles: ['company'],
        title: 'Uni Onboarding',
        path: PATH_COMPANY.admin.uniOnboarding,
        slugCode: 'admin-uni-onboarding',
      },
      {
        roles: ['company'],
        title: 'Add Career Track',
        path: PATH_COMPANY.admin.CareerTrackCreate,
        icon: ICONS.newJobPosting,
        slugCode: 'admin-career-track',
      },
      {
        roles: ['company'],
        title: 'Career Track',
        path: PATH_COMPANY.admin.CareerTrackList,
        icon: ICONS.ctroadmap,
        slugCode: 'admin-career-track',
      },
      
      {
        roles: ['company'],
        title: 'Test',
        path: PATH_COMPANY.admin.testList,
        icon: ICONS.test,
        children: [
          {
            roles: ['company'],
            title: 'Create Test',
            path: PATH_COMPANY.admin.createTest,
            icon: ICONS.newJobPosting,
            // slugCode: 'admin-create-test'
          },
          {
            roles: ['company'],
            title: 'All Tests',
            path: PATH_COMPANY.admin.testList,
            icon: ICONS.test,
            // slugCode: 'admin-create-test'
          },
          {
            roles: ['company'],
            title: 'Questions',
            path: PATH_COMPANY.admin.questionList,
            icon: ICONS.question,
            // slugCode: 'admin-create-test'
          },
          {
            roles: ['company'],
            title: 'Test Builder',
            path: PATH_COMPANY.admin.testBuilder,
            icon: ICONS.testBuilder,
            // slugCode: 'admin-create-test'
          },
          {
            roles: ['company'],
            title: 'Create Question',
            path: PATH_COMPANY.admin.addQuestion,
            icon: ICONS.newJobPosting,
            // slugCode: 'admin-create-test'
          },
        ],
      },
    ],
  },
  {
    subheader: 'For You',
    items: [
      {
        roles: ['company'],
        title: 'Settings',
        path: PATH_COMPANY.user.CompanyAccount,
        icon: ICONS.StudentSettings,
      },
    ],
  },
];

// if (REACT_APP_ENVIRONMENT === 'production') {
//   // Remove the last three items in navConfig
//   navConfig.splice(-3);
// }

const navConfig = [
  {
    role: 'student',
    items: studentNavConfig,
  },
  {
    role: 'university',
    items: universityNavConfig,
  },
  {
    role: 'company',
    items: companyNavConfig,
  },
];

export default navConfig;
