import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  progress: {
    courses: false,
  students: false,
  teammates: false,
  tutorials: false,
  },
  modalOpen: false,
};

const slice = createSlice({
  name: 'addStudent',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get All COurses for University
    getUserCourse(state, action) {
        state.isLoading = false;
        state.availableCourses = action.payload;
    },

    // Create User Add Student
    uniAddStudent(state, action) {
      state.isLoading = false;
      state.addStudentReq = action.payload;
    },

    updateProgress(state, action) {
      const type = action.payload;
      state.progress[type] = true;
    },

    updateAllProgress(state, action) {
      const { courses, students, teammates, tutorials } = action.payload;

      state.progress.courses = courses;
      state.progress.students = students;
      state.progress.teammates = teammates;
      state.progress.tutorials = tutorials;
    },

    // OPEN MODAL
    openModal(state) {
      state.modalOpen = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.modalOpen = false;
    },
  },
});

// Reducer
export default slice.reducer;



export function getAddUserCourse() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try{
            const response = await axios.get(`/api/v1/accounts/university/add/getcourse/`);
            dispatch(slice.actions.getUserCourse(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}



// ----------------------------------------------------------------------

export function uniAddStudent( firstName, lastName, email, course, specialization, year, section, rollNumber) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/v1/accounts/university/add/student/`, { firstName, lastName, email, course, specialization, year, section, rollNumber });
      dispatch(slice.actions.uniAddStudent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function setOnboardingComplete() {
  return async () => {
    try {
      await axios.post(`/api/v1/auxiliary/university-onboarding-tour-complete/`);
    } catch (error) {
      console.error('Error setting onboarding complete:', error);
    }
  };
}

export function getOnboardingProgress() {
  return async () => {
    try {
      const response = await axios.get(`/api/v1/auxiliary/university-onboarding-progress/`);
      dispatch(slice.actions.updateAllProgress(response.data));
    } catch (error) {
      console.error('Error getting onboarding progress:', error);
    }
  };
}

export function updateOnboardingProgress(progress) {
  return async () => {
    try {
      await axios.post(`/api/v1/auxiliary/university-onboarding-progress/`, { progress });
      dispatch(slice.actions.updateProgress(progress));
    } catch (error) {
      console.error('Error updating onboarding progress:', error);
    }
  };
}

export function openModal() {
  return async () => {
    dispatch(slice.actions.openModal());
  };
}

export function closeModal() {
  return async () => {
    dispatch(slice.actions.closeModal());
  };
}