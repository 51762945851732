import React from 'react';
import { Box, Typography, Stack, Paper, Button } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import Iconify from '../Iconify';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.neutral,
  borderRadius: theme.shape.borderRadiusMd,
  boxShadow: theme.customShadows.z8,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 80,
  height: 80,
  color: '#1D6F42', // Excel green color
  animation: `${pulse} 2s ease-in-out infinite`,
}));

const FileNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export default function ExcelUploadSuccess({ file, onUpload }) {
  return (
    <StyledPaper>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ textAlign: 'center' }}
      >
        <IconStyle icon="mdi:file-excel" />

        <Box>
          <Typography variant="h5" gutterBottom color="primary.main">
            Excel File Selected!
          </Typography>
          <Typography variant="body1" paragraph>
            Your file is ready for upload. Click the button below to start the import process.
          </Typography>
          <FileNameTypography variant="body2">
            {file?.name || 'No file selected'}
          </FileNameTypography>
        </Box>


      </Stack>
    </StyledPaper>
  );
}