import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Container,
  Grid,
  Divider,
  useTheme,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import { LoginForm } from '../../sections/auth/login';
import LogoLogin from '../../components/LogoLogin';
import Image from '../../components/Image';

const AutoSlider = () => {
  const theme = useTheme();
  const slides = [
    {
      text: 'VarsityPro helps you make data-driven & informed choices planning your journey to success.',
      image: '/logo/image 8.svg',
    },
    {
      text: 'Find jobs, internships, and opportunities all at one-platform for students across India.',
      image: '/logo/image 5.svg',
    },
    {
      text: 'VarsityPro brings workshops carried out by Industry professionals to your students, empowering them to explore, learn, and grow.',
      image: '/logo/image 6.svg',
    },
    {
      text: 'With VarsityPro’s fresher-oriented resume templates, students no longer have to scavenge the web for resumes.',
      image: '/logo/image 7.svg',
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Box
        sx={{
          height: '240px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {slides.map((slide, index) => (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              opacity: index === currentIndex ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              component="img"
              src={slide.image}
              alt={`Slide ${index + 1}`}
              sx={{
                width: '80px',
                height: '80px',
                objectFit: 'contain',
                mb: 2,
              }}
            />
            <Typography
              variant="body1"
              align="center"
              sx={{
                px: 2,
                maxWidth: '90%',
              }}
            >
              {slide.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {slides.map((_, index) => (
          <Box
            key={index}
            onClick={() => setCurrentIndex(index)}
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: index === currentIndex ? theme.palette.primary.main : theme.palette.grey[300],
              mx: 0.5,
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: index === currentIndex ? theme.palette.primary.dark : theme.palette.grey[400],
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

const LoginComponent = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    window.location.href = 'https://varsitypro.club/get-a-demo-of-varsitypro/';
  };
  return (
    <Box
      sx={{
        backgroundImage: "url('/logo/Rectangle 6.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Card
          sx={{
            backgroundColor: 'white',
            borderRadius: '16px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          <IconButton
            onClick={handleBackClick}
            sx={{
              position: 'absolute',
              top: 16,
              left: 16,
              zIndex: 1,
            }}
          >
            <ArrowBack />
          </IconButton>
          <CardContent sx={{ padding: 4, mb: 6 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <Image src="/logo/vp-logo.png" alt="VP Logo" sx={{ width: '30%', height: '30%',cursor:'pointer' }} onClick={handleBackClick}/>
                </Box>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <AutoSlider />
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mx: 2, display: { xs: 'none', sm: 'block' } }} />
                <Grid item xs={12} sm={5}>
                  <LoginForm />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default LoginComponent;
