import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';



const initialState = {
    isLoading: false,
    error: null,
    user: null,
    navPages: [],
  };



  const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      // GET USER
      getUserSuccess(state, action) {
        state.isLoading = false;
        state.user = action.payload;
      },

      // GET NAV PAGES
      getNavPagesSuccess(state, action) {
        state.isLoading = false;
        state.navPages = action.payload;
      },
    },
  });
  
  // Reducer
  export default slice.reducer;


  export function getUser() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        // const accessToken = localStorage.getItem('accessToken');

        // setSession(accessToken);

        const response = await axios.get('/api/v1/accounts/user');
        console.log('chal rahah he')
        dispatch(slice.actions.getUserSuccess(response.data.user));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  // Reducer to get list of pages authorised for the user role
  export function getNavPages() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/v1/accounts/nav-page');
        dispatch(slice.actions.getNavPagesSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  // Reducer to update user general settings
  // export function updateGeneralSettings({ firstName, lastName, email, mobileNumber, address }) {
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.post('/api/v1/accounts/update-general-settings', {
  //         firstName,
  //         lastName,
  //         email,
  //         mobileNumber,
  //         address,
  //       });
  //       dispatch(slice.actions.getUserSuccess(response.data.user));
  //     } catch (error) {
  //       dispatch(slice.actions.hasError(error));
  //     }
  //   };
  // }