import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, Typography, Box, Stepper, Step, StepLabel, TextField, Autocomplete, Alert, AlertTitle, Paper, Grid } from '@mui/material';
import { m, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import useAuth from '../../../../../hooks/useAuth';
import Iconify from '../../../../Iconify';
import { firstOn } from '../../../../../assets';
import axios from '../../../../../utils/axios';
import { openModal } from '../../../../../redux/slices/uniOnboarding/uniOnboarding';


const placementData = [
  { year: '2019', rate: 75 },
  { year: '2020', rate: 78 },
  { year: '2021', rate: 82 },
  { year: '2022', rate: 88 },
  { year: '2023', rate: 92 },
  { year: '2024', rate: 95 },
];

const steps = [
  { label: 'Welcome', icon: 'mdi:hand-wave' },
  { label: 'What are we?', icon: 'mdi:school' },
  { label: 'Student Management', icon: 'mdi:account-group' },
  { label: 'Assessment Tools', icon: 'mdi:clipboard-text-clock' },
  { label: 'Placement Portal', icon: 'mdi:briefcase-account' },
  { label: 'Analytics Dashboard', icon: 'mdi:chart-bar' },
  { label: 'Get Started', icon: 'mdi:rocket-launch' },
];

const MotionBox = m(Box);

const backgroundImages = [
    '/assets/university-onboarding/dashboard.png',
    '/api/placeholder/1920/1080?text=University Profile',
    '/api/placeholder/1920/1080?text=Student Management',
    '/api/placeholder/1920/1080?text=Assessment Tools',
    '/api/placeholder/1920/1080?text=Placement Portal',
    '/api/placeholder/1920/1080?text=Analytics Dashboard',
    '/api/placeholder/1920/1080?text=Get Started',
  ];

const OnboardingDialog = () => {
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [universityName, setUniversityName] = useState('');
  const [universityType, setUniversityType] = useState('');
  const [isUniversityUserNotOnboarded, setIsUniversityUserNotOnboarded] = useState(false);
  const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();





  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleComplete = async () => {
    try{
        const response = await axios.post('/api/v1/auxiliary/dismiss-university-onboarding/');
        if(response.status === 200){
            setIsUniversityUserNotOnboarded(false);
            dispatch(openModal());            
        }
    }catch(error){
        console.error('Error onboarding university user:', error);
    }
    };

  useEffect(() => {
    if (user && user.role === 'university' && user.universityUserTourCompleted === false) {
      setIsUniversityUserNotOnboarded(true);
    }
  }, [user]);


  const FeatureCard = ({ icon, title, description }) => (
    <Paper elevation={3} sx={{ 
      p: 3, 
      height: '100%', 
      transition: 'transform 0.3s, box-shadow 0.3s',
      '&:hover': { 
        transform: 'translateY(-5px)',
        boxShadow: 6,
      }
    }}>
      <Iconify icon={icon} style={{ fontSize: 40, color: '#4CAF50' }} />
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>{title}</Typography>
      <Typography variant="body2">{description}</Typography>
    </Paper>
  );

  const StepContent = ({ step }) => {
    switch (step) {
      case 0:
        return (
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            <Typography variant="h3" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 700 }}>
              Welcome to VarsityPro
            </Typography>
            <Typography variant="h5" gutterBottom>
              Revolutionize Your University's Career Services
            </Typography>
            
            <Typography variant="body1" paragraph>
              VarsityPro is your all-in-one solution for managing student careers, streamlining placements, and boosting your institution's reputation.
            </Typography>
            <Alert variant="outlined" severity="info" sx={{ mt: 2 }}>
              <AlertTitle>Did You Know?</AlertTitle>
              Universities using VarsityPro have seen an average 15% increase in their placement rates within the first year!
            </Alert>
          </MotionBox>
        );
      case 1:
        return (
            <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <Box sx={{ textAlign: 'center', mb: 6 }}>
              <Typography 
                variant="h3" 
                sx={{ 
                  fontWeight: 'bold',
                  background: 'linear-gradient(45deg, #2196F3 30%, #4CAF50 90%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2
                }}
              >
                A Toolkit for Placement Officers
              </Typography>
              <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                We are a Dual user platform
              </Typography>
            </Box>
      
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FeatureCard
                  icon="mdi:school"
                  title="University's Career Development Cell"
                  description="Take tests, conduct assessments, and manage placements, view advanced ananlytics on student's career readiness."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FeatureCard
                  icon="mdi:account-group"
                  title="Students"
                  description="They build their profile, score resumes, take tests, and apply for jobs, while being in a guided environment."
                />
              </Grid>
            </Grid>
      
            
          </MotionBox>
        );
      case 2:
        return (
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
              Comprehensive Student Management
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', my: 4 }}>
              {['Profile Tracking', 'Resume Analysis', 'Skill Assessment', 'Career Readiness'].map((feature, index) => (
                <MotionBox
                  key={index}
                  sx={{
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 2,
                    p: 2,
                    m: 1,
                    width: '45%',
                    textAlign: 'center',
                    boxShadow: 2
                  }}
                  whileHover={{ scale: 1.05, boxShadow: 4 }}
                >
                  <Iconify icon={`mdi:${['account', 'file-document', 'brain', 'flag-checkered'][index]}`} width={40} height={40} />
                  <Typography variant="h6" sx={{ mt: 1 }}>{feature}</Typography>
                </MotionBox>
              ))}
            </Box>
            <Typography variant="body1" paragraph>
              Effortlessly manage your students' profiles, track their progress, and identify areas for improvement.
            </Typography>
          </MotionBox>
        );
      case 3:
        return (
          <MotionBox
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
              Advanced Assessment Tools
            </Typography>
            <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
              <MotionBox
                component="img"
                src={firstOn}
                alt="VarsityPro Dashboard"
                sx={{ maxWidth: '70%', maxHeight: '300px' }}
                whileHover={{ scale: 1.05 }}
                transition={{ type: 'spring', stiffness: 300 }}
              />
            </Box>
            <Typography variant="body1" paragraph>
              Conduct aptitude tests, coding challenges, and personality assessments with our state-of-the-art tools.
            </Typography>
            <Alert severity="warning" sx={{ mt: 2 }}>
              <AlertTitle>Attention</AlertTitle>
              Regular assessments can increase student placement readiness by up to 40%!
            </Alert>
          </MotionBox>
        );
      case 4:
        return (
          <MotionBox
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
              Streamlined Placement Portal
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', my: 4 }}>
              {['Job Approvals', 'Company Connections', 'Interview Scheduling', 'Offer Management'].map((feature, index) => (
                <MotionBox
                  key={index}
                  sx={{
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 2,
                    p: 2,
                    m: 1,
                    width: '45%',
                    textAlign: 'center',
                    boxShadow: 2
                  }}
                  whileHover={{ scale: 1.05, boxShadow: 4 }}
                >
                  <Iconify icon={`mdi:${['checkbox-marked-circle', 'handshake', 'calendar-clock', 'file-sign'][index]}`} width={40} height={40} />
                  <Typography variant="h6" sx={{ mt: 1 }}>{feature}</Typography>
                </MotionBox>
              ))}
            </Box>
            <Typography variant="body1" paragraph>
              Manage the entire placement process from job postings to offer letters, all in one place.
            </Typography>
          </MotionBox>
        );
      case 5:
        return (
          <MotionBox
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
              Powerful Analytics Dashboard
            </Typography>
            <Box sx={{ height: 300, mt: 4 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={placementData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="rate" stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Typography variant="body1" paragraph sx={{ mt: 2 }}>
              Gain valuable insights into placement trends, student performance, and areas for improvement.
            </Typography>
            <Alert severity="info" sx={{ mt: 2 }}>
              <AlertTitle>NIRF Ranking Boost</AlertTitle>
              VarsityPro not only saves your time and money but also helps you improve your institution's ranking!
            </Alert>
          </MotionBox>
        );
      case 6:
        return (
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
              Ready to Transform Your Career Services?
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <MotionBox
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleComplete}
                  startIcon={<Iconify icon="mdi:rocket-launch" />}
                >
                  Launch VarsityPro
                </Button>
              </MotionBox>
            </Box>
            <Typography variant="body1" paragraph>
              Click the button above to complete your onboarding and start using VarsityPro. Our team is here to support you every step of the way!
            </Typography>
            <Alert severity="success" sx={{ mt: 2 }}>
              <AlertTitle>Congratulations!</AlertTitle>
              You're about to join a revolution in student career services in India. Welcome to VarsityPro!
            </Alert>
          </MotionBox>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isUniversityUserNotOnboarded}
      fullScreen
      PaperProps={{
        style: {
          background: 'transparent',
          overflow: 'hidden',
        },
      }}
    >
      <DialogContent sx={{ p: 0, overflow: 'hidden' }}>
        <Box sx={{ 
          height: '100vh', 
          display: 'flex', 
          flexDirection: 'column',
          background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        }}>
          <Box sx={{ bgcolor: 'rgba(255,255,255,0.9)', p: 2 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel StepIconComponent={() => (
                    <m.div
                      initial={false}
                      animate={{
                        scale: activeStep === index ? 1.2 : 1,
                        color: activeStep === index ? theme.palette.primary.main : theme.palette.text.secondary,
                      }}
                    >
                      <Iconify icon={step.icon} width={24} height={24} />
                    </m.div>
                  )}>
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            p: 4, 
            overflowY: 'auto',
            bgcolor: 'rgba(255,255,255,0.7)',
          }}>
            <AnimatePresence mode='wait'>
              <m.div
                key={activeStep}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                style={{ width: '100%', maxWidth: 1200 }}
              >
                <StepContent step={activeStep} />
              </m.div>
            </AnimatePresence>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            p: 3, 
            bgcolor: 'rgba(255,255,255,0.9)',
            boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)'
          }}>
            <m.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button 
                onClick={handleBack} 
                disabled={activeStep === 0}
                startIcon={<Iconify icon="mdi:arrow-left" />}
                sx={{ 
                  visibility: activeStep === 0 ? 'hidden' : 'visible',
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                }}
                variant="outlined"
              >
                Back
              </Button>
            </m.div>
            <m.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button
                variant="contained"
                onClick={activeStep === steps.length - 1 ? handleComplete : handleNext}
                endIcon={<Iconify icon={activeStep === steps.length - 1 ? "mdi:check" : "mdi:arrow-right"} />}
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: 'white',
                }}
              >
                {activeStep === steps.length - 1 ? 'Get Started' : 'Next'}
              </Button>
            </m.div>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
);
};

export default OnboardingDialog;