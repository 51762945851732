import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  eventsList: [],
  pageInfo: {},
  postsListUni: [],
  feedPageInfo: {},
  workshopsListUni: [],
  // Add other fields specific to university events here
};

const slice = createSlice({
  name: 'uniErp',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JOBS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.eventsList = action.payload.results;
      state.pageInfo = { totalEvents: action.payload.count }
    },

    getEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload;
    },

    getFeedSuccessUni(state, action) {
      state.isLoading = false;
      state.postsListUni = action.payload.results;
      state.feedPageInfo = { totalFeeds: action.payload.count }
    },

    getWorkshopsSuccessUni(state, action) {
      state.isLoading = false;
      state.workshopsListUni = action.payload;
    }
  },
});

export default slice.reducer;

export function listEvents(eventTypeOL, eventTypeUN, searchTerm = '', pageNumber = '1') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let appendString = ""
      appendString += `et_ol=${eventTypeOL}&et_un=${eventTypeUN}`
      const response = await axios.get(`/api/v1/erp/university-events/view/?search=${searchTerm}&page=${pageNumber}&${appendString}`);
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getEvent(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/${id}`);
      dispatch(slice.actions.getEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function listFeedUni(pageNumber = '1') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/university-feed/university/list/?page=${pageNumber}`);
      dispatch(slice.actions.getFeedSuccessUni(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function listWorkshopsUni() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/university-workshop/university/list/`);
      // console.log(response)
      dispatch(slice.actions.getWorkshopsSuccessUni(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
