import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  availableCourses : [],
  addStudentReq: null,
};

const slice = createSlice({
  name: 'addStudent',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get All COurses for University
    getUserCourse(state, action) {
        state.isLoading = false;
        state.availableCourses = action.payload;
    },

    // Create User Add Student
    uniAddStudent(state, action) {
      state.isLoading = false;
      state.addStudentReq = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;



export function getAddUserCourse() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try{
            const response = await axios.get(`/api/v1/accounts/university/add/getcourse/`);
            dispatch(slice.actions.getUserCourse(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}



// ----------------------------------------------------------------------

export function uniAddStudent( firstName, lastName, email, course, specialization, year, section, rollNumber) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/v1/accounts/university/add/student/`, { firstName, lastName, email, course, specialization, year, section, rollNumber });
      dispatch(slice.actions.uniAddStudent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

