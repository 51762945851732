import { clarity } from 'react-microsoft-clarity';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import BannerContainer from './components/modals/Banner/BannerContainer';
import useAuth from './hooks/useAuth';


// ----------------------------------------------------------------------

export default function App() {
  const {user} = useAuth();

  let userEmail;

  if (user) {
    userEmail = user.email;
  } else {
    userEmail = 'anonymous';
  }

  if (process.env.REACT_APP_ENVIRON === 'production') {
  clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);
  clarity.identify('email', userEmail);
  };

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
          <BannerContainer />
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
