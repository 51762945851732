import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Container, Typography, Button, Link } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string), // Example ['admin', 'leader']
  slugCode: PropTypes.string,
  isNav : PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default function RoleBasedGuard({ roles, slugCode, isNav, children }) {
  // Logic here to get current user role
  const { user } = useAuth();

  function getPageSlugs(userTypePageAuth) {
    const pageSlugs = userTypePageAuth?.page?.map((page) => page.slug);
    return pageSlugs;
  }

  const pageSlugs = getPageSlugs(user?.userTypePageAuth);

  // const currentRole = 'user';
  const currentRole = user?.role; // admin;


  if (slugCode && currentRole) {
    if (!pageSlugs?.includes(slugCode)) {
      return !isNav ? (
        <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Permission Denied
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Uh Oh, looks like you don't have privilege to access this page. Contact your University Administrator to get access.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Think this is a mistake? <Link href="https://varsitypro.zendesk.com/" target="_blank" rel="noreferrer">Visit Support</Link>
            </Typography>
          </m.div>
        </Container>
      ) : null
    }
  } else if (currentRole && typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    console.log('uuuu');
    return !isNav ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>You might be tring to access something you don't have full access to.</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
        <Button variant="contained" color="primary" onClick={()=> window.location.replace('/auth/login')}>
          Go Home
        </Button>
        <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Think this is a mistake? <Link href="https://varsitypro.zendesk.com/" target="_blank" rel="noreferrer">Visit Support</Link>
            </Typography>
          </m.div>
      </Container>
    ) : null
  }

  if (currentRole) {
    return <>{children}</>;
  }
  return null;
  // return <>{children}</>;
}
