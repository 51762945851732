import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  jobsList: [],
  pageInfo: {},
  jobCreate: {},
  companyJobsList: [],
  studentAppliedJobsList: [],
  AppliedJobsPageInfo: {},
  uniHiringDriveList: [],
  uniHiringDriveListPageInfo: {},
  hdApplicantsList: [],
  hdApplicantsPageInfo: {},
  uniJobsList: [],
  uniJobsListLive: [],
  studentHiringDriveList: [],
  studentHiringDrivePageInfo: {},
};

const slice = createSlice({
  name: 'jobOpening',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JOBS
    getJobsSuccess(state, action) {
      state.isLoading = false;
      state.jobsList = action.payload.results;
      state.pageInfo = { totalJobs: action.payload.count }
    },

    // Get SINGLE JOB
    getJobSuccess(state, action) {
      state.isLoading = false;
      state.job = action.payload;
    },

    // Get SINGLE JOB
    getCustomQuestions(state, action) {
      state.isLoading = false;
      state.customQuestions = action.payload;
    },

    // GET Hiring Drive List student
    getstudentHiringDriveSuccess(state, action) {
      state.isLoading = false;
      state.studentHiringDriveList = action.payload.results;
      state.studentHiringDrivePageInfo = { totaldrives: action.payload.count }
    },

    // CREATE JOBS
    createJobSuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.jobCreate = newJob;
    },


    createHiringDriveSuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.hiringDriveCreate = newJob;
    },

    // GET JOBS
    getCompanyJobsSuccess(state, action) {
      state.isLoading = false;
      state.companyJobsList = action.payload;
    },


    // GET Student Applied JOBS
    getStudentAppliedJobsSuccess(state, action) {
      state.isLoading = false;
      state.studentAppliedJobsList = action.payload.results;
      state.AppliedJobsPageInfo = { totalJobs: action.payload.count }
    },

    // When get a chance, move Student hiring drive to here from ERP,
    // Previously, it was a seprate model in ERP, but now has been merged with job model

    // GET Hiring Drive List university
    getHiringDriveUniversityListSuccess(state, action) {
      state.isLoading = false;
      state.uniHiringDriveList = action.payload.results;
      state.uniHiringDriveListPageInfo = { totaldrives: action.payload.count }
    },

    getHDApplicantsSuccess(state, action) {
      state.isLoading = false;
      state.hdApplicantsList = action.payload.results;
      state.hdApplicantsPageInfo = { totalApplicants: action.payload.count }
    },

    getUniJobsSuccess(state, action) {
      state.isLoading = false;
      state.uniJobsList = action.payload;
    },

    getUniListJobsSuccess(state, action) {
      state.isLoading = false;
      state.uniJobsListLive = action.payload;
    },

    createAdminJobSuccess(state, action) {
      const newJob = action.payload;
      state.isLoading = false;
      state.jobCreate = newJob;
    },

    getAdminJobsSuccess(state, action) {
      state.isLoading = false;
      state.companyJobsList = action.payload;
    },

    // UPDATE EVENT
    // updateEventSuccess(state, action) {
    //   const event = action.payload;
    //   const updateEvent = state.events.map((_event) => {
    //     if (_event.id === event.id) {
    //       return event;
    //     }
    //     return _event;
    //   });

    //   state.isLoading = false;
    //   state.events = updateEvent;
    // },

    // DELETE EVENT
    // deleteEventSuccess(state, action) {
    //   const { eventId } = action.payload;
    //   const deleteEvent = state.events.filter((event) => event.id !== eventId);
    //   state.events = deleteEvent;
    // },

    // SELECT EVENT
    // selectEvent(state, action) {
    //   const eventId = action.payload;
    //   state.isOpenModal = true;
    //   state.selectedEventId = eventId;
    // },

    // SELECT RANGE
    // selectRange(state, action) {
    //   const { start, end } = action.payload;
    //   state.isOpenModal = true;
    //   state.selectedRange = { start, end };
    // },

    // OPEN MODAL
    // openModal(state) {
    //   state.isOpenModal = true;
    // },

    // CLOSE MODAL
    // closeModal(state) {
    //   state.isOpenModal = false;
    //   state.selectedEventId = null;
    //   state.selectedRange = null;
    // },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function listJobs(industry, citySelected, stateSelected, countrySelected, jobTypeIntership, jobTypeFullTime, jobTypeFellowship, searchTerm = '', pageNumber = '1') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let appendString = ""
      appendString += `jt_it=${jobTypeIntership}&jt_ft=${jobTypeFullTime}&jt_fp=${jobTypeFellowship}`
      if (industry) {
        appendString += `&industry=${industry}`
      }
      const response = await axios.get(`/api/v1/jobs/view/?search=${searchTerm}&page=${pageNumber}&${appendString}&city=${citySelected}&state=${stateSelected}&country=${countrySelected}`);
      console.log("----------------------", response)
      dispatch(slice.actions.getJobsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function getJob(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/${id}`);
      dispatch(slice.actions.getJobSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function getCustomQuestion(jobId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/custom-questions/${jobId}/`);
      dispatch(slice.actions.getCustomQuestions(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function listStudentHiringDrive() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/hiring-drive/view/`);
      dispatch(slice.actions.getstudentHiringDriveSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// -------------------------------------------------------------------
export function createJob(newJob) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/jobs/company/job/new/', newJob);
      dispatch(slice.actions.createJobSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error);
    }
  };
}

// ----------------------------------------------------------------------

export function listCompanyJobs(isActive) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get(`/api/v1/jobs/company/job/view/?active=true`);
      const response = await axios.get(`/api/v1/jobs/company/job/list/`);
      dispatch(slice.actions.getCompanyJobsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// ----------------------------------------------------------------------

export function listStudentAppliedJobs(pageNumber) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/applied-jobs/?page=${pageNumber}`);
      dispatch(slice.actions.getStudentAppliedJobsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function listUniversityHiringDriveList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/university/hiring-drive/view/`);
      dispatch(slice.actions.getHiringDriveUniversityListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// -------------------------------------------------------------------
export function createHiringDrive(newJob) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/jobs/university/hiring-drive/new/', newJob);
      dispatch(slice.actions.createHiringDriveSuccess(response.data));
    } catch (error) {
      console.log('Error a rahah he bhai', error)
      dispatch(slice.actions.hasError(error));
      throw new Error(error);
    }
  };
}

// ----------------------------------------------------------------------

export function updateHiringDrive(id, updateJob) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/v1/jobs/university/hiring-drive/update/${id}/`, updateJob);
      dispatch(slice.actions.createHiringDriveSuccess(response.data));
    } catch (error) {
      console.log('Error a rahah he bhai', error)
      dispatch(slice.actions.hasError(error));
      throw new Error(error);
    }
  };
}

// ----------------------------------------------------------------------
// This is for the applicants of a single hiring drive that the university staff sees
export function listHDApplicants(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/university/hiring-drive/applicants/${id}`);
      dispatch(slice.actions.getHDApplicantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------
// THis is for listing all the jobs, approved, penjing and rejected thaat te univeristy staff sees on main page
export function listUniJobs() {
  // THis lists all jobs be it approved, pending or rejected
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/university/jobs/view/`);
      dispatch(slice.actions.getUniJobsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function listUniJobsLive() {
  // THis lists all jobs be it approved or live, pending or rejected
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/university/jobs/list/`);
      dispatch(slice.actions.getUniListJobsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// -------------------------------------------------------------------
export function createAdminJob(newJob) {
  console.log('newJob', newJob)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/jobs/admin/new/', newJob, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important to set the content type
        },
      });
      dispatch(slice.actions.createAdminJobSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error);
    }
  };
}


// -------------------------------------------------------------------

export function listAdminJobs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/admin/company/view/`);
      dispatch(slice.actions.getAdminJobsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function updateEvent(eventId, updateEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/update', {
//         eventId,
//         updateEvent,
//       });
//       dispatch(slice.actions.updateEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function deleteEvent(eventId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post('/api/calendar/events/delete', { eventId });
//       dispatch(slice.actions.deleteEventSuccess({ eventId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function selectRange(start, end) {
//   return async () => {
//     dispatch(
//       slice.actions.selectRange({
//         start: start.getTime(),
//         end: end.getTime(),
//       })
//     );
//   };
// }
