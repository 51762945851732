import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  eventsList: [],
  pageInfo: {},
  studentHiringDriveList: [],
  studentHiringDrivePageInfo: {},
  studentActivity: [],
  studentDetails: [],
  postsListUni: [],
  postsListStudent: [],
  feedPageInfo: {},
  workshopsListStudent: [],
};

const slice = createSlice({
  name: 'erp',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JOBS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.eventsList = action.payload.results;
      state.pageInfo = { totalEvents: action.payload.count }
    },

    getEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload;
    },


    // GET Hiring Drive List student
    getstudentHiringDriveSuccess(state, action) {
      state.isLoading = false;
      state.studentHiringDriveList = action.payload.results;
      state.studentHiringDrivePageInfo = { totaldrives: action.payload.count }
    },

    // GET Student Activity
    getStudentActivitySuccess(state, action) {
      state.isLoading = false;
      state.studentActivity = action.payload;
    },

    getStudentDetailsSuccess(state, action) {
      state.isLoading = false;
      state.studentDetails = action.payload;
    },

    // GET FEED
    getFeedSuccessUni(state, action) {
      state.isLoading = false;
      state.postsListUni = action.payload;
    },

    getFeedSuccessStudent(state, action) {
      state.isLoading = false;
      state.postsListStudent = action.payload.results;
      state.feedPageInfo = { totalFeeds: action.payload.count }
    },

    getWorkshopsSuccessStudent(state, action) {
      state.isLoading = false;
      state.workshopsListStudent = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function listEvents(eventTypeOL, eventTypeUN, searchTerm = '', pageNumber = '1') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let appendString = ""
      appendString += `et_ol=${eventTypeOL}&et_un=${eventTypeUN}`
      const response = await axios.get(`/api/v1/erp/events/view/?search=${searchTerm}&page=${pageNumber}&${appendString}`);
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------


export function getEvent(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/${id}`);
      dispatch(slice.actions.getEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function listFeedUni() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/university-feed/university/list/`);
      dispatch(slice.actions.getFeedSuccessUni(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function listFeedStudent() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/university-feed/student/list/`);
      dispatch(slice.actions.getFeedSuccessStudent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function listStudentHiringDrive() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/jobs/hiring-drive/view/`);
      dispatch(slice.actions.getstudentHiringDriveSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function listStudentActivity() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/student-activity/view/`);
      dispatch(slice.actions.getStudentActivitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function listStudentDetails() {
  // This funtion lists the details of students of a university for the univeristy admin
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/student-details/view/`);
      dispatch(slice.actions.getStudentDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

// ----------------------------------------------------------------------

export function listWorkshopsStudent() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/erp/university-workshop/student/list/`);
      // console.log(response)
      dispatch(slice.actions.getWorkshopsSuccessStudent(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// -------------------------------------------------------------------
// export function createEvent(newEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/new', newEvent);
//       dispatch(slice.actions.createEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function updateEvent(eventId, updateEvent) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/update', {
//         eventId,
//         updateEvent,
//       });
//       dispatch(slice.actions.updateEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

// export function deleteEvent(eventId) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post('/api/calendar/events/delete', { eventId });
//       dispatch(slice.actions.deleteEventSuccess({ eventId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function selectRange(start, end) {
//   return async () => {
//     dispatch(
//       slice.actions.selectRange({
//         start: start.getTime(),
//         end: end.getTime(),
//       })
//     );
//   };
// }
